var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bj", attrs: { id: "presented" } },
    [
      _c("div", { staticClass: "cell" }, [
        _c(
          "label",
          {
            staticClass: "form-label",
            class: _vm.is_ios ? "ios-sty" : "",
            attrs: { for: "mobile" },
          },
          [_vm._v("\n      受赠人手机号：\n    ")]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.mobile,
              expression: "mobile",
            },
          ],
          attrs: {
            required: "",
            id: "mobile",
            placeholder: "请输入受赠人手机号码",
            type: "number",
          },
          domProps: { value: _vm.mobile },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.mobile = $event.target.value
            },
          },
        }),
      ]),
      _vm.businessType == 1
        ? _c("div", { staticClass: "cell" }, [
            _c(
              "label",
              {
                staticClass: "form-label",
                class: _vm.is_ios ? "ios-sty" : "",
                attrs: { for: "give_value" },
              },
              [_vm._v("\n      赠送余额：\n    ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.give_value,
                  expression: "give_value",
                },
              ],
              attrs: {
                required: "",
                type: "text",
                placeholder: _vm.title,
                id: "give_value",
              },
              domProps: { value: _vm.give_value },
              on: {
                change: function ($event) {
                  return _vm.getValue(_vm.give_value)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.give_value = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.businessType == 2
        ? _c("div", { staticClass: "cell" }, [
            _c(
              "label",
              {
                staticClass: "form-label",
                class: _vm.is_ios ? "ios-sty" : "",
                attrs: { for: "give_values" },
              },
              [_vm._v("\n      赠送积分：\n    ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.give_value,
                  expression: "give_value",
                },
              ],
              attrs: {
                type: "text",
                required: "",
                placeholder: _vm.title,
                id: "give_values",
              },
              domProps: { value: _vm.give_value },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.give_value = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "cell" }, [
        _c(
          "label",
          {
            staticClass: "form-label",
            class: _vm.is_ios ? "ios-sty" : "",
            attrs: { for: "password" },
          },
          [_vm._v("\n      支付密码：\n    ")]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          attrs: {
            type: "password",
            required: "",
            placeholder: "请输入您的支付密码",
            id: "password",
          },
          domProps: { value: _vm.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password = $event.target.value
            },
          },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "btn-con",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.goGiveUserWealth.apply(null, arguments)
            },
          },
        },
        [
          _c("MyButton", {
            attrs: { title: "发起赠送", is_disabled: _vm.is_disabled },
          }),
        ],
        1
      ),
      _c("ConfirmAlert", {
        attrs: {
          value: _vm.no_pwd,
          is_delete: false,
          confirmText: "抱歉，您还没有设置支付密码",
          confirmBtnText: "去设置",
        },
        on: {
          cancel: function ($event) {
            _vm.no_pwd = false
          },
          confirm: _vm.confirmPwd,
        },
      }),
      _c("yd-keyboard", {
        ref: "keyboard",
        attrs: { callback: _vm.checkPwd, disorder: "", title: "" },
        model: {
          value: _vm.show_keyboard,
          callback: function ($$v) {
            _vm.show_keyboard = $$v
          },
          expression: "show_keyboard",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }