<template>
  <div id="presented" class="bj">
    <div class="cell">
      <label for="mobile" class="form-label" :class="is_ios ? 'ios-sty' : ''">
        受赠人手机号：
      </label>
      <input
        v-model="mobile"
        required
        id="mobile"
        placeholder="请输入受赠人手机号码"
        type="number"
      />
    </div>
    <div v-if="businessType == 1" class="cell">
      <label
        for="give_value"
        class="form-label"
        :class="is_ios ? 'ios-sty' : ''"
      >
        赠送余额：
      </label>
      <input
        required
        type="text"
        v-model="give_value"
        :placeholder="title"
        id="give_value"
        @change="getValue(give_value)"
      />
    </div>
    <div v-if="businessType == 2" class="cell">
      <label
        for="give_values"
        class="form-label"
        :class="is_ios ? 'ios-sty' : ''"
      >
        赠送积分：
      </label>
      <input
        type="text"
        required
        v-model="give_value"
        :placeholder="title"
        id="give_values"
      />
    </div>
    <div class="cell">
      <label for="password" class="form-label" :class="is_ios ? 'ios-sty' : ''">
        支付密码：
      </label>
      <input
        type="password"
        required
        v-model="password"
        placeholder="请输入您的支付密码"
        id="password"
      />
    </div>
    <div class="btn-con" @click.stop="goGiveUserWealth">
      <MyButton title="发起赠送" :is_disabled="is_disabled"></MyButton>
    </div>
    <!--没有支付密码弹窗-->
    <ConfirmAlert
      :value="no_pwd"
      :is_delete="false"
      confirmText="抱歉，您还没有设置支付密码"
      confirmBtnText="去设置"
      @cancel="no_pwd = false"
      @confirm="confirmPwd"
    ></ConfirmAlert>
    <!--安全键盘-->
    <yd-keyboard
      v-model="show_keyboard"
      :callback="checkPwd"
      disorder
      ref="keyboard"
      title
    ></yd-keyboard>
  </div>
</template>

<script>
import MyButton from "@/components/button/button";
import { mapState } from "vuex";
import { getWealthInfo, giveUserWealth } from "@/services/my";
import { is_ios } from "@/utils/common.js";
import ConfirmAlert from "@/components/common/ConfirmAlert";
import md5 from "js-md5";
import { debounce } from "@/utils/debounce";
import setRsaPass from "@/utils/jsencrypt";
import { customAccount } from "@/utils/custom";

export default {
  name: "presented",
  components: {
    ConfirmAlert,
    MyButton
  },
  inject: ["reload"],
  data() {
    return {
      mobile: "",
      give_value: "",
      title: "最多可赠予0.00",
      value: "",
      no_pwd: false, //是否设置支付密码
      is_disabled: false,
      is_ios: is_ios(),
      password: "",
			isJyFl: false //江阴福利
    };
  },
  computed: {
    ...mapState({
      businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      pay_password: state => state.userInfo.pay_password
    })
  },
  methods: {
		//江阴福利
		isjy() {
		  if (customAccount("VUE_APP_JY")) {
				this.isJyFl = true;
		  }
		},
		
    getValue(val) {
      let num = val.replace(/[^\d^\.]+/g, "");
      this.give_value = Number(num).toFixed(2);
    },
    async getWealthInfo() {
      const res = await getWealthInfo();
      if (res.code == 0) {
        this.title = "最多可赠予" + res.data.value;
        this.value = res.data.value;
      } else {
        this.$dialog.toast({
          mes: res.msg,
          timeout: 500
        });
      }
    },
    //去设置支付密码
    confirmPwd() {
      this.$router.push({
        name: "verify_phone",
        query: {
          function_type: 1
        }
      });
    },
    goGiveUserWealth: debounce(async function() {
      const regMobile = /^1[3|4|5|6|7|8|9]\d{9}$/;
      const regNumber = /^[0-9]*$/;
      const regDecimal = /^([1-9]\d*.?|0.)\d*$/;
      if (!regMobile.test(this.mobile)) {
        this.$dialog.toast({
          mes: "请填写正确的手机号",
          timeout: 500
        });
        return;
      }
      if (this.businessType == 1) {
        if (!regDecimal.test(this.give_value) || Number(this.give_value) < 1) {
          this.$dialog.toast({
            mes: "余额输入有误，请重新输入！",
            timeout: 500
          });
          this.give_value = "";
          return;
        }
      } else {
        if (!regNumber.test(this.give_value) || Number(this.give_value) < 1) {
          this.$dialog.toast({
            mes: "积分只能输入整数，请重新输入！",
            timeout: 500
          });
          this.give_value = "";
          return;
        }
      }

      if (this.give_value > Number(this.value)) {
        this.$dialog.toast({
          mes: "超过赠予上限,请重新输入!",
          timeout: 500
        });
        this.give_value = "";
        return;
      }
      if (!this.password) {
        this.$dialog.toast({
          mes: "请输入您的支付密码！",
          timeout: 500
        });
        return;
      } else if (!this.pay_password) {
        this.no_pwd = true; //是否设置支付密码
        return;
      } /* else if (this.pay_password != md5(md5(this.password))) {
        this.$dialog.toast({
          mes: "您的支付密码不正确，请重新输入！",
          timeout: 500
        });
        this.password = "";
        return;
      } */
      let unit = this.businessType == 1 ? "元" : "积分";
      this.$dialog.confirm({
        title: "亲,请核对您的赠予信息",
        mes: `</br>您将赠予手机账号为${this.mobile}的用户
               <span class="give_value">${this.give_value + unit}</span>
               </br>
               </br>`,
        opts: [
          {
            txt: "取消",
            color: "#353535"
          },
          {
            txt: "确定",
            color: true,
            callback: async () => {
              const params = {
                mobile: this.mobile,
                give_value: this.give_value,
								password: this.isJyFl ? setRsaPass(this.password) : md5(this.password)
              };
              const res = await giveUserWealth(params);
              if (res.code == 0) {
                this.$dialog.toast({
                  mes: res.msg,
                  timeout: 500
                });
                setTimeout(() => {
                  this.reload();
                }, 500);
              } else {
                this.$dialog.toast({
                  mes: res.msg,
                  timeout: 500
                });
              }
            }
          }
        ]
      });
    }, 300)
  },
  created() {
    this.getWealthInfo();
		this.isjy();
  }
};
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";
.give_value {
  font-weight: 600;
  color: #666;
}
.yd-cell:after {
  margin-left: 0.24rem;
}
#presented {
  height: 100%;
  width: 100%;
  position: absolute;
  // position: relative;
  // top: 0;
  // left: 0;
  // background: #fff;
  .bj(#fff);
  padding: 0.4rem 0.2rem;
  .ios-sty {
    margin-top: 0.1rem;
  }
  .cell {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.3rem 0.3rem 0.3rem 0.1rem;
    border-bottom: 1px solid @color-row-line;
    input {
      width: 60%;
    }
    &::-webkit-input-placeholder {
      color: #b3b3b3;
    }
  }
  .btn-con {
    margin-top: 1rem;
  }
}
</style>
