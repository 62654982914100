import JSEncrypt from "jsencrypt";
export default function setRsaPass(psw) {
  let jse = new JSEncrypt();
  let my = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxSZAo+oZjk0KoH69CUS7
QAZXDXdQ46CFZrEeT1qFWw64RpFFA43MfKteZels0MfjB7GFhuWmpBjTQiewdJ4b
WFDHJ9bsH7Nv+8VzAZaQ0gZzjZdjVPLVzCi3dtqkmlkT+TLR/S6K2W8DLY5X9hCE
qSCpdw+dP3tWV51v78utp5PsUy6Y4UlXR8I5YYAnlyNo6NZ4FXsaduAPR2c2k3V0
WWnTULusrBroKUSvfNqrnPcCJ9IXehS+vj7vEWPUc22jOdw1z1uRt3xvuIxoQmnW
Lki3iu7H4EeIYiy3TLIIzmXkvBpAyPHUQRlQW5JeNLErwGrzj1i06bTbu6BcfG3Z
XwIDAQAB`;
  jse.setPublicKey(`-----BEGIN PUBLIC KEY-----${my}-----END PUBLIC KEY-----`);
  // let Base64 = require('js-base64').Base64
  return jse.encrypt(psw.toString());
}
